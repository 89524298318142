import * as Sentry from '@sentry/gatsby'

Sentry.init({
    dsn: 'https://778b9f85ea1047509b4ac30f3a9b8a3c@o4504119317757952.ingest.sentry.io/4504119318872064',
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
})
